*{
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
.body{
    background: #222831;
}
.pages{
    padding-top: 2rem;
    background: #222831;
}


/*.hero-section{
    background-color: var(--overlay);
    height: 80vh;
    width: 100%;
    position: relative;
}
.hero-section::before{
    content: '';
    background: url('./components/images/developer.png') no-repeat center center /100% 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1000;
}*/
/*.hero-section{
    width: 100%;
    height: 100vh;
    background: url('../images/developer.png') no-repeat center center / cover;
    place-content: center;
    place-items: center;
    background-attachment: fixed;

}*/

/*@media screen and (max-width:768px){
    .hero-section{
        background-color: var(--overlay);
        height: 90vh;
        width: 100%;
        position: relative;
    }
}

@media screen and (max-width:600px){
    .hero-section{
        height: 50vh;
        width: 100%;
        background-attachment: scroll;
    }
}

@media screen and (max-width:400px){
    .hero-section{
        height: 50vh;
        width: 100%;
        background-attachment: scroll;
    }
}*/