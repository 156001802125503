.skill{
    background: #d4d8dd;
    padding: 4rem 3.5rem;
    display: flex;
    flex-direction: column;
}

.skill-header{
    display: flex;
    position: relative;
    white-space: 0;
    justify-content: center;
    align-items: center;;
}
.skill-header h2{
    font-size: 4rem;
}

.skill-header img{
    margin-bottom: -3rem;
    margin-left: 1rem;
    width: 40px;
}
.skill-wrapper{
    padding-top: 2rem;
    display: grid;
    gap: 2.5rem;
    grid-template-columns: repeat(6, 1fr);
}


.skill-wrapper img{
    max-width: 140px;
    width: 100%;
    object-fit: contain;
    height: auto;
    border: 1px solid rgb(119, 178, 220);
    border-radius: 10%;
    -webkit-border-radius: 10%;
    -moz-border-radius: 10%;
    -ms-border-radius: 10%;
    -o-border-radius: 10%;
    transition: 0.6s ease-in-out;
    -webkit-transition: 0.6s ease-in-out;
    -moz-transition: 0.6s ease-in-out;
    -ms-transition: 0.6s ease-in-out;
    -o-transition: 0.6s ease-in-out;
}

.skill-wrapper img:hover{
    border: 1.5px dotted #1d3344;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.slick-dots li button:before{
    color:#d4d8dd;
    font-size: 1rem;
}

.slick-dots li.slick-active button:before {
  color: rgb(119, 178, 220);
}


@media screen and (max-width:768px){
    .skill{
        padding: 4rem 1.5rem;
        display: flex;
        flex-direction: column;
    }
    .skill-wrapper{
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
    }

}
@media screen and (max-width:425px){
    .skill-wrapper{
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
    }

    .skill h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        margin-top: 0;
    }
    .skill span{
        font-size: 6rem;
    }
}

