.contact-links{
    background-color: #222832;
    padding: 5rem 3.5rem 1.5rem 3.5rem;
}
.logo-1{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
}
.logo-1 img{
    max-width: 130px;
    width: 100%;
    height: 100%;
    padding-bottom: 0.2rem;
}
.logo-1 span{
    color: rgb(119, 178, 220);
}
.footer-links{
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.social-links{
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    order: 1;
}
.footer-links .social-links li{
    display: inline;
    list-style-type: none;
    margin-right: 3rem;
}
.footer-links .social-links li a{
    display: inline;
    text-decoration: none;
    font-size: 2rem;
    color: rgb(119, 178, 220);
    text-transform: capitalize;
}
.footer-links .social-links li a:hover{
    color: #d4d8dd;
    text-transform: capitalize;
}

.footer-menu{
    order: 2;
}

.footer-menu ul{
    display: flex;
    justify-content: space-between;
}
.footer-menu ul li{
    list-style-type: none;
    margin-left: 1.4rem;

}
.footer-menu ul li a{
    text-decoration: none;
    color: #d4d8dd;
    font-size: 1.1rem;
    text-transform: capitalize;

}
.footer-menu ul li a:hover{
    color: rgb(119, 178, 220);
}

hr{
    margin-top: 1.2rem;
}


@media screen and (max-width:768px){
    .contact-links{
        padding: 5rem 1.5rem 1rem 1.5rem;
    }

    .footer-links .social-links li a{
        font-size: 1.8rem;
    }

    .footer-menu{
        order: 1;
    }
    .footer-menu ul li{
        margin-left: 0.7rem;
    }
}


@media screen and (max-width:600px){
    .footer-links{
        flex-direction: column;
        justify-content: center;
        /*padding: 3rem 8rem 0rem 8rem;*/
    }
    .footer-links .social-links{
        order: 2;
    }
    .footer-links .social-links li{
        margin: 0rem 1.4rem;
    }

    .footer-menu ul li{
        margin: 1rem 0.7rem;
    }
}
