.about{
    padding: 5rem 3.5rem;
    background-color: #222831;
}
.about-container{
    display: flex;
    padding-top: 2rem;
    justify-content: space-between;
    color: #d4d8dd;
}
.about-box{
    flex-basis: 44%;
    padding: 0rem 0rem 2rem 0rem;
    text-align: justify;
}

.profile-pix{
    /*max-width: 450px;
    */width: 100%;
    height: auto;
    border-radius: 10%;
    -webkit-border-radius: 10%;
    -moz-border-radius: 10%;
    -ms-border-radius: 10%;
    -o-border-radius: 10%;
}

.namedisplay h1{
    font-size: 2.5rem;
    text-align: center;
    margin: 1.2rem 0rem;
}
#name p{
    font-size: 1.1rem;
    line-height: 1.5;
}
#name .hire-button{
    margin: 2.5rem 0rem;
    padding: 0.6rem;
    border-radius: 1rem;
    width: 170px;
    height: 50px;
    text-align: center;
    border-width: 0rem  ;
    font-size: 1.4rem;
    color: #d4d8dd;
    box-shadow: 3px 3px 5px rgb(119, 178, 220);;
}
#name .hire-button:hover{
    background: rgb(119, 178, 220);
    color: #d4d8dd;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
#name .hire-button a{
    color: #222831;
    text-decoration: none;
}
#name .hire-button a:hover{
    color: #d4d8dd;
}
@media screen and (max-width:880px){
    .profile-pix{
        max-width: 450px;
        width: 100%;
        height: auto;
        margin-top: 2rem;
        border-bottom-left-radius: 20%;
        border-top-right-radius: 20%;
    }
    .about-box{
        padding: 0rem;
    }
}
@media screen and (max-width:768px){
    .about{
        padding: 2rem 1.5rem 1rem 1.5rem;
        margin-top: 3rem;
    }
    .about-container{
        display: flex;
        flex-direction: column;
    }
    #pix-box{
        order: 1;
        margin: auto;
    }
    #name{
        order: 2;
        margin-top: 1.5rem;
    }
    #name p{
        font-size: 1.1rem;
        line-height: 1.5;
        text-align: justify;
    }
    .profile-pix{
        width: 100%;
        height: auto;
        border-radius: 20%;
    }

    .profile-pix:hover{
        border-radius: 20%;
    }
}
@media screen and (max-width:500px){
    .namedisplay h1{
        font-size: 1.5rem;
        margin: 1.2rem 0rem 0rem 0rem;
    }
    .profile-pix{
        width: 100%;
        height: auto;
    }
}
/*============hire-modal==============*/
.hire-me-modal{
    background: #222831;
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 290px;
    top: 50%;
    left: 50%;
    border-radius: 2rem;
    visibility: visible;
    transform: translate(-50%,-50%);
    position: fixed;
    opacity: 1;
}
.modal-content{
    top: 50%;
    left: 50%;
    max-width: 330px;
    max-height: 270px;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    background: #fdf3f2;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 2rem;
}
.hire-me-modal p{
    font-size: 1.2rem;
    color: rgb(41, 39, 39);
    line-height: 1.8;
}
@media screen and (max-width:400px){
    .hire-me-modal{
        background: rgb(132, 117, 117);
        width: 100%;
        height: 100%;
        max-width: 330px;
        max-height: 270px;
    }
    .modal-content{
        max-width: 310px;
        max-height: 250px;
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        padding: 1.4rem 1.5rem;
    }
}