.nav-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: fixed;
    width: 100%;
    padding: 0rem 3.5rem;
    background-color: #222831;
    color: #d4d8dd;
    overflow: hidden;
    z-index: 2000;
}
.nav-bar .nav-menu{
    display: flex;
    list-style-type: none;

}

.nav-menu .active{
    border-bottom: 2px solid rgb(119, 178, 220);
}

.nav-bar .nav-menu .nav-item{
    list-style-type: none;
    padding: 0rem 0rem 0rem 2rem;
    display: inline;
}
.nav-bar .nav-menu .nav-item a{
    color: #d4d8dd;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.2rem;
    list-style-type: none;
    text-transform: capitalize;
}
.nav-bar .nav-menu .nav-item a:hover{
    color: rgb(201, 194, 194);
    border-bottom: 2px solid rgb(119, 178, 220);
}
.logo{
    margin-top: 0.3rem;
}
.hamburger{
    display: none;
}
.my-number p{
    font-size: 0.8rem;
    padding-left: 0.5rem;
}

@media screen and (max-width:768px){
    .nav-bar{
        height: 80px;
        padding: 0rem 1.5rem;
        justify-content: space-between;
    }
    .hamburger{
        display: block;
    }
    .nav-bar .nav-menu{
        width: 100%;
        height: 300px;
        position: fixed;
        margin: 0.5rem auto 0rem 0rem;
        display: flex;
        justify-content: left;
        align-items: left;
        left: -100%;
        background-color: #222831;
        top: 72px;
        z-index: 1000;
        padding: 1.5rem 0.8rem;
        flex-direction: column;
        text-align: left;
        transition: 0.8s all ease;
        -webkit-transition: 0.8s all ease;
        -moz-transition: 0.8s all ease;
        -ms-transition: 0.8s all ease;
        -o-transition: 0.8s all ease;
    }
    .nav-menu.active{
        left: 0;
    }

    .nav-bar .nav-menu .nav-item{
        padding: 1rem;
    }
    .nav-bar .nav-menu .nav-item a{
        color: #fff;
    }
    .nav-bar .nav-menu .nav-item a:hover{
        color: #fff;
        border-bottom: 3px solid rgb(119, 178, 220);
    }
}