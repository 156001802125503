.contact{
    background: #d4d8dd;
    padding: 3rem 3.5rem;
}
.contact-card{
    background: #222831;
    margin: auto;
    color: #d4d8dd;
    padding: 3rem;
    max-width: 700px;
    width: 100%;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
}
.contact-card p{
    font-size: 2.5rem;
    font-weight: 700;
}
.contact-card p:nth-child(2){
    margin-bottom: 4rem;
}
.contact-button{
    height: 60px;
    width: 210px;
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    border-width: 0rem;
}
.contact-button:hover{
    background-color: rgb(119, 178, 220);
    transition: all 0.5s ease-in-out;
    box-shadow: 5px 4px 5px #787878;
    color: #fff;
}
@media screen and (max-width:768px){
    .contact{
        padding: 3rem 1.5rem;
    }
    .contact-card p{
        font-weight: 500;
        font-size: 1.8rem;
        text-align: start;
    }
    .contact-button{
        width: 200px;
        font-size: 1.2rem;
    }
}
@media screen and (max-width:496px){
    .contact-card{
        padding: 3rem 1.6rem;
    }
    .contact-card p{
        font-weight: 600;
        font-size: 1.5rem;
        text-align: justify;
    }
    .contact-button{
        width: 180px;
    }
}
/*==========email-modal=============*/
.email-modal{
    background: #222831;
    width: 100%;
    height: 100%;
    max-width: 570px;
    max-height: 350px;
    top: 50%;
    left: 50%;
    border-radius: 2rem;
    visibility: visible;
    transform: translate(-50%,-50%);
    position: fixed;
    opacity: 1;
}
.email-content{
    top: 50%;
    left: 50%;
    max-width: 550px;
    max-height: 340px;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    background: #d4d8dd;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 1.6rem;
}

input, textarea{
    margin: 0.2rem 1rem;
    padding: 0.3rem;
    max-width: 250px;
    width: 100%;
    font-size: 1.1rem;
    border-radius: 0.3rem;
}
#submit{
    background-color: #222831;
    color: #d4d8dd;
    border-width: 0rem;
    cursor: pointer;
    border-radius: 0.3rem;
}
submit:hover{
    background-color: rgb(119, 178, 220);
    color: rgb(41 39 39);
    border-width: 0rem;
    cursor: pointer;
    border-radius: 0.3rem;
}

@media screen and (max-width:600px){
    .email-modal{
        width: 100%;
        max-width: 316px;
    }
    .email-content{
        max-width: 314px;
        max-height: x;
        width: 100%;
        height: 100%;
        padding: 1.1rem 1.4rem;
    }

    input, textarea{
        margin: 0.2rem 0.6rem;
        padding: 0.3rem;
        max-width: 240px;
        width: 100%;
    }
}