.footer{
    background: #222831;
    padding: 1rem 3.5rem 1.5rem 3.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #d4d8dd;
}

@media screen and (max-width:450px){
    .footer{
        padding: 1rem 1.5rem;
    }
}